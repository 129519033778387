.imgBox {
  width: 100%;
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.img {
  width: 55vw;
  height: 55vh;
  object-fit: contain;
}
