.grid {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px auto 50px;
}

.typo {
  height: 100%;
}
