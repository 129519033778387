.picture {
  position: absolute;
  width: 35vw;
  height: 15vh;
  right: 10%;
  top: 12%;

  img {
    object-fit: contain;
    width: inherit;
    height: inherit;
  }
}
